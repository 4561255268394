import React, { FC, useCallback } from "react";
import { useField } from "formik";
import { FieldHookConfig } from "formik/dist/Field";
import { TextField, TextFieldProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export type FormFieldProps = {
  version?: string;
  title?: string;
  name: string;
  maxLength?: number;
  labelClassName?: string;
  fieldProps?: Partial<FieldHookConfig<string>>;
} & Omit<TextFieldProps, "name">;

export const FieldForm: FC<FormFieldProps> = ({
  version,
  title,
  maxLength,
  labelClassName,
  fieldProps,
  onBlur,
  ...props
}) => {
  const { t } = useTranslation();
  const [{ onBlur: onFieldBlur, ...field }, { touched, error }] = useField({
    name: props.name,
    ...fieldProps,
  });

  const handleBlur = useCallback(
    (e) => {
      onFieldBlur?.(e);
      onBlur?.(e);
    },
    [onBlur, onFieldBlur]
  );

  const isError = touched && !!error;

  if (version === "profile") {
    return (
      <label className={`field-form-label ${labelClassName || ""}`}>
        <Typography pb={0.5} color="secondary" pl={2} className="title">
          {title}
        </Typography>
        <TextField
          {...field}
          InputProps={{ disableUnderline: true }}
          sx={{
            "& div": {
              borderRadius: "8px",
            },
            "& .MuiFilledInput-input": {
              padding: "10px 16px",
              borderRadius: "8px",
              textOverflow: "ellipsis",
            },
          }}
          fullWidth
          variant="filled"
          size="small"
          helperText={isError ? t(error as string, { maxLength }) : undefined}
          onBlur={handleBlur}
          error={isError}
          {...props}
        />
      </label>
    );
  }

  return (
    <TextField
      {...field}
      onBlur={handleBlur}
      error={isError}
      helperText={isError ? t(error as string) : undefined}
      {...props}
    />
  );
};
